import React, { useState } from "react";

const CostCalculator = () => {
  const [callsPerWeek, setCallsPerWeek] = useState(0);
  const [timePerCall, setTimePerCall] = useState(0);
  const [annualSalary, setAnnualSalary] = useState(65000);
  const [weeklyCost, setWeeklyCost] = useState(0);
  const [monthlyCost, setMonthlyCost] = useState(0);

  const handleCalculate = () => {
    const hourlyRate = annualSalary / 52 / 40; // Calculate hourly rate (52 weeks, 40 hours/week)
    const weeklyHours = (callsPerWeek * timePerCall) / 60; // Total weekly hours spent on calls
    const weeklyCost = weeklyHours * hourlyRate; // Weekly cost
    const monthlyCost = weeklyCost * 4.33; // Monthly cost (average weeks in a month)
    
    setWeeklyCost(weeklyCost.toFixed(2)); // Round to 2 decimal places for weekly cost
    setMonthlyCost(monthlyCost.toFixed(2)); // Round to 2 decimal places for monthly cost
  };

  return (
    <div className="calculator">
      <div className="form-group">
        <label>Number of Calls per Week:</label>
        <input
          type="number"
          className="form-control"
          value={callsPerWeek}
          onChange={(e) => setCallsPerWeek(Number(e.target.value))}
        />
      </div>
      <div className="form-group">
        <label>Average Time per Call (in minutes):</label>
        <input
          type="number"
          className="form-control"
          value={timePerCall}
          onChange={(e) => setTimePerCall(Number(e.target.value))}
        />
      </div>
      <div className="form-group">
        <label>Annual Salary of Staff ($):</label>
        <input
          type="number"
          className="form-control"
          value={annualSalary}
          onChange={(e) => setAnnualSalary(Number(e.target.value))}
        />
      </div>
      <button className="btn btn-primary mt-3" onClick={handleCalculate}>
        Calculate Weekly and Monthly Cost
      </button>

      {weeklyCost > 0 && (
        <div className="result mt-4">
          <h4>Total Weekly Cost: ${weeklyCost}</h4>
          <h4>Total Monthly Cost: ${monthlyCost}</h4>
        </div>
      )}
    </div>
  );
};

export default CostCalculator;
