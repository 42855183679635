import React from "react";
import { useLocation } from "react-router-dom";

export const Navigation = (props) => {
  const location = useLocation();

  // Check if the current route is '/about-mims'
  const isAboutMimsPage =
    location.pathname === "/mims" || location.pathname === "/privacy" || location.pathname === "/signup" || location.pathname === "/SpecialistOEC";

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
          <img src="img/ehwmims50px.png" alt="eHealthWise"/>
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            {isAboutMimsPage ? (
              <>
                <li>
                  <a href="/#features" className="page-scroll">
                    Features
                  </a>
                </li>
                <li>
                  <a href="/#about" className="page-scroll">
                    About
                  </a>
                </li>
                <li>
                  <a href="/#services" className="page-scroll">
                    Services
                  </a>
                </li>
                <li>
                  <a href="/#contact" className="page-scroll">
                    Contact
                  </a>
                </li>
              </>
            ) : (
              <>
                <li>
                  <a href="#features" className="page-scroll">
                    Features
                  </a>
                </li>
                <li>
                  <a href="#about" className="page-scroll">
                    About
                  </a>
                </li>
                <li>
                  <a href="#services" className="page-scroll">
                    Services
                  </a>
                </li>
                <li>
                  <a href="#contact" className="page-scroll">
                    Contact
                  </a>
                </li>
              </>
            )}

            {/* Add "Thelma Portal" with dropdown menu */}
            <li className="dropdown">
              <a
                href="#"
                className="dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Thelma Portal <span className="caret"></span>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a href="https://thelma.com.au/logon/login.jsp">Thelma User</a>
                </li>
                <li>
                  <a href="https://sahealth.thelma.com.au/logon/login.jsp">South Australia Health User</a>
                </li>
                <li>
                  <a href="https://signup.thelma.com.au/register">New User Sign Up</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
