import React, { useState } from "react";
import emailjs from "emailjs-com";
import { Link } from "react-router-dom";

const initialState = {
  name: "",
  title:"",
  company: "",
  orgtype:"",
  email: "",
  phone: "",
  message: "",
};

export default function Signup(props) {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);
    emailjs
      .sendForm(
        "service_459xjjh",
        "template_cnauqbe",
        e.target,
        "A7bAiKzqc5VD9hbs9"
      )
      .then(
        (result) => {
          console.log(result.text);
          clearState();
          e.target.reset(); // Reset the form
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-lg-12">
            <div className="row">
              <div className="section-title">
                <h3>THELMA New Provider Application Form</h3>   
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Full Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="title"
                        name="title"
                        className="form-control"
                        placeholder="Position"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="company"
                        name="company"
                        className="form-control"
                        placeholder="Organisation Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="orgtype"
                        id="orgtype"
                        name="orgtype"
                        className="form-control"
                        placeholder="Organisation Type eg: Hospital, Clinic"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        className="form-control"
                        placeholder="Phone"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Required transaction. Fund check, Hospital claim or Medical claim"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <div class="col-lg-2 col-lg-offset-2 col-md-2 col-sm-10">
            <Link to="/mims">About MIMS</Link>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-10">
            <Link to="/privacy">Privacy</Link>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-10">
            &copy; 2024 eHealthWise{" "}
          </div>
        </div>
      </div>
    </div>


  );
}


