import React from "react";
import { Link } from "react-router-dom";
import CostCalculator from "./CostCalculator"
function SpecialistOEC(props) {
  return (
    <div>
    <div id="eligibility">
      <div className="container">
        <div className="section-title text-center">   
          <h1>Introducing Online Eligibility Check for Specialists</h1>
        </div>
          <p className="lead fst-italic mb-4">
            Simplifying Patient Financial Transparency for Specialist Doctors
          </p>

          <section className="mb-5">
            <h2 className="h3 mb-3">Why Financial Transparency Matters</h2>
            <p>
              Patients deserve to know their financial obligations before receiving care. The Department of Health recommends that healthcare providers offer cost estimates to patients before treatment to promote <strong>Informed Financial Consent</strong>. This includes disclosing:
            </p>
            <ul className="list-unstyled">
              <li>• The total cost of the episode of care.</li>
              <li>• Medicare and/or Private Health Insurer (PHI) benefits.</li>
              <li>• Expected out-of-pocket expenses.</li>
            </ul>
          </section>

          <section className="mb-5">
            <h2 className="h3 mb-3">The Challenge for Specialists</h2>
            <p>
              While hospitals and day surgeries have streamlined access to eligibility information, specialists have been left behind, relying on time-consuming phone calls or emails to gather this data.
            </p>
          </section>

          <section className="mb-5">
            <h2 className="h3 mb-3">The Solution: Online Eligibility Check (OEC) for Specialists</h2>
            <p>
              eHealthwise is proud to bring the <strong>Online Eligibility Check Web Service (OECW)</strong> to specialist doctors through our <strong>THELMA platform</strong>. This innovative service, previously available only to hospitals, now empowers specialists with:
            </p>
            <ul className="list-unstyled">
              <li>• <strong>Real-Time PHI Eligibility Checks</strong>: Confirm your patient's eligibility for their presenting condition or illness on the proposed date of treatment.</li>
              <li>• <strong>Out-of-Pocket Estimates</strong>: Access clear information on excess, exclusions, and co-payments for hospital stays, prosthetic services, and MBS services.</li>
              <li>• <strong>Streamlined Workflow</strong>: Skip the back-and-forth with insurers and get the information you need instantly.</li>
            </ul>
          </section>

          <section className="mb-5">
            <h2 className="h3 mb-3">Who Benefits from OEC for Specialists?</h2>
            <div className="row">
              <div className="col-md-6">
                <h3 className="h4">Doctors</h3>
                <p>Save time, reduce administrative burden, and provide patients with accurate cost information faster.</p>
              </div>
              <div className="col-md-6">
                <h3 className="h4">Patients</h3>
                <p>Gain clarity about their financial responsibilities, empowering them to make informed decisions about their care.</p>
              </div>
            </div>
          </section>

          <section className="mb-5">
            <h2 className="h3 mb-3">Key Features</h2>
            <ul className="list-unstyled">
              <li>• <strong>MBS Services</strong>: Determine the Medicare contribution for eligible treatments.</li>
              <li>• Hospital Stay Coverage: Check PHI benefits for inpatient care.</li>
              <li>• Prosthetic Services: Confirm coverage for prosthetics and related services.</li>
            </ul>
          </section>

          {/* Embed Cost Calculator */}
          <section className="mb-5">
            <h2 className="h3 mb-3">Calculate the Cost of Administrative Tasks</h2>
            <p>
              Use our calculator to estimate the cost of time spent on administrative tasks like calling insurers for eligibility information.
            </p>
            <CostCalculator />
          </section>


          <section className="text-center">
            <h2 className="h3 mb-4">Experience OEC for Specialist Today!</h2>
            <p className="lead">
              Our solution is the first of its kind tailored for specialist doctors. Designed with your unique needs in mind, the OEC integration via THELMA makes financial transparency effortless.
            </p>
            <a href="https://signup.thelma.com.au/register/specialist" className="btn btn-custom">
              Get Started with OEC
            </a>
          </section>
        </div>
      </div>
      <div id="footer-mims">
        <div className="container text-center">
          <div class="col-lg-2 col-lg-offset-2 col-md-2 col-sm-10">  
          <Link to="/mims">About MIMS</Link>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-10">       
          <Link to="/privacy">Privacy</Link>
          </div> 
          <div class="col-lg-2 col-md-2 col-sm-10">
          &copy; 2024 eHealthWise{" "}
          </div>
        </div>
      </div>
  
    </div>
        
  );
};

export default SpecialistOEC;