import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import MIMS from "./components/mims";
import Privacy from "./components/privacy";
import SpecialistOEC from "./components/SpecialistOEC";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import Signup from "./components/signup";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    setLandingPageData(JsonData);

    // Add Google Analytics 4 script
    const gaScript = document.createElement("script");
    gaScript.async = true;
    gaScript.src = `https://www.googletagmanager.com/gtag/js?id=G-XXXXXXXXXX`;
    document.head.appendChild(gaScript);

    gaScript.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-XXXXXXXXXX');
    };
    
    // Add Tawk.to script
    const script = document.createElement("script");
    script.async = true;
    script.src = 'https://embed.tawk.to/66ba9ed6146b7af4a4399505/1i54fou1o';
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');
    document.body.appendChild(script);

    // Cleanup script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Router>
      <div>
        <Navigation />
        <Routes>
          <Route path="/" element={<Home landingPageData={landingPageData} />} />
          <Route path="/mims" element={<MIMS landingPageData={landingPageData}/>} />
          <Route path="/SpecialistOEC" element={<SpecialistOEC landingPageData={landingPageData}/>} />
          <Route path="/privacy" element={<Privacy landingPageData={landingPageData}/>} />
          <Route path="/signup" element={<Signup landingPageData={landingPageData}/>} /> 
        </Routes>
      </div>
    </Router>
  );
};

const Home = ({ landingPageData }) => {
  return (
    <>
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      <Services data={landingPageData.Services} />
      <Gallery data={landingPageData.Gallery} />
      <Testimonials data={landingPageData.Testimonials} />
      <Team data={landingPageData.Team} />
      <Contact data={landingPageData.Contact} />
    </>
  );
};

export default App;
